import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { ReactComponent as GridIcon } from "assets/icons/goods.svg";
import GoodsGrid from "components/GoodsGrid";
import * as fabric from "fabric";
import { Suspense, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";
import { getCollageDetails, getCollageGoods } from "services/api";
import BottomModal from "../bottom_modal/BottomModal";

export function CollageModal({ isOpen, onClose, collageId, openGoodModal }) {
  const canvasRef = useRef(null);
  const fabricCanvas = useRef(null);
  const {
    data: collageDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["collageDetails", collageId],
    queryFn: () => getCollageDetails(collageId),
  });

  const {
    data: collageGoods,
    isPending: isPendingGoods,
    isFetching: isFetchingGoods,
    isLoading: isLoadingGoods,
    hasNextPage: hasNextPageGoods,
    isFetchingNextPage: isFetchingNextPageGoods,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["collageGoods", collageId],
    queryFn: ({ pageParam = 0 }) => getCollageGoods(collageId, pageParam),
    getNextPageParam: (lastPage, pages) => lastPage?.offset || null,
    enabled: collageId != null && isOpen,
  });

  const { ref, inView: inViewGoods } = useInView({
    threshold: 0,
    rootMargin: "0px 0px 500px 0px",
  });

  if (inViewGoods && hasNextPageGoods && !isPendingGoods && !isFetchingGoods) {
    fetchNextPage();
  }

  useEffect(() => {
    const wrapper = document.getElementById("canvas-modal-wrapper");
    // Initialize Fabric.js canvas

    fabricCanvas.current = new fabric.Canvas(canvasRef.current, {
      width: wrapper.clientHeight * (9 / 16),
      height: wrapper.clientHeight,
      backgroundColor: "transparent",
      renderOnAddRemove: true,
      enablePointerEvents: true,
      selection: false,
      hoverCursor: "default",
      allowTouchScrolling: true,
    });

    const resizeCanvas = () => {
      let canvasWidth, canvasHeight;

      canvasWidth = wrapper.clientHeight * (9 / 16);
      canvasHeight = wrapper.clientHeight;

      if (canvasWidth > wrapper.clientWidth) {
        canvasWidth = wrapper.clientWidth;
        canvasHeight = wrapper.clientWidth * (16 / 9);
      }
      fabricCanvas.current.setDimensions({
        width: canvasWidth,
        height: canvasHeight,
      });
    };
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    fabricCanvas.current.preserveObjectStacking = true;

    // Clean up on unmount
    return () => {
      fabricCanvas.current.dispose();
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [collageDetails]);

  useEffect(() => {
    if (!collageDetails || isLoading || isError) return;
    const canvasJSON = JSON.parse(collageDetails["json"]);
    const originalCanvasWidth = canvasJSON.width; // Assuming width is stored in the JSON
    const originalCanvasHeight = canvasJSON.height; // Assuming height is stored in the JSON

    const newCanvasWidth = fabricCanvas.current.width;
    const newCanvasHeight = fabricCanvas.current.height;

    const widthScaleFactor = newCanvasWidth / originalCanvasWidth;
    const heightScaleFactor = newCanvasHeight / originalCanvasHeight;

    // Use the smaller of the two to maintain the aspect ratio
    const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);

    fabricCanvas.current.loadFromJSON(collageDetails["json"]).then((e) => {
      fabricCanvas.current.getObjects().forEach((obj) => {
        obj.scaleX *= scaleFactor;
        obj.scaleY *= scaleFactor;
        obj.hoverCursor = "pointer";

        // Adjust positions based on scaling factor
        obj.left *= scaleFactor;
        obj.top *= scaleFactor;
        obj.selectable = false; // Disable selection
        obj.evented = true; // Allow event listening (like clicks)
        obj.setCoords();
      });

      let isDragging = false;
      let lastPosX;
      let lastPosY;

      // Handle object clicks and drags
      fabricCanvas.current.on("mouse:down", function (options) {
        isDragging = false;
        let evt = options.e;
        lastPosX = evt.clientX;
        lastPosY = evt.clientY;
      });

      fabricCanvas.current.on("mouse:move", function (options) {
        let evt = options.e;
        let dx = evt.clientX - lastPosX;
        let dy = evt.clientY - lastPosY;
        if (dx * dx + dy * dy > 25) {
          // 5 pixels threshold squared
          isDragging = true;
        }
      });

      fabricCanvas.current.on("mouse:up", function (options) {
        if (!isDragging) {
          const clickedObject = options.target;
          if (clickedObject && clickedObject.goodId) {
            openGoodModal(clickedObject.goodId);
          }
        }
      });

      fabricCanvas.current.renderAll();
    });

    // Clean up event listeners on unmount
    return () => {
      fabricCanvas.current.off("mouse:down");
      fabricCanvas.current.off("mouse:move");
      fabricCanvas.current.off("mouse:up");
    };
  }, [collageDetails]);

  const navigate = useNavigate();
  const location = useLocation();
  const onCloseModal = () => {
    navigate(location.pathname, { replace: true });
    onClose();
  };
  return (
    <BottomModal
      className={"pt-0"}
      isOpen={isOpen}
      onClose={onCloseModal}
      zIndex={20}
      containerClassName={" max-w-[550px] h-full"}
    >
      <div
        className={`flex justify-center relative w-full h-[70vh] bg-no-repeat rounded-t-3xl mb-4`}
        id="canvas-modal-wrapper"
        style={{
          backgroundColor:
            collageDetails?.bg_color !== "transparent"
              ? collageDetails?.bg_color
              : "#F7F7F7",
        }}
      >
        <canvas id="collage-modal-canvas" ref={canvasRef} className="" />
      </div>
      <div className="px-4">
        <p className="text-lg font-bold mb-4">{collageDetails?.title}</p>
        <p className="mb-4 text-grey-1">
          <GridIcon className="inline w-5 h-5 mr-2 mb-1 text-grey-2" />
          Collage Goods
        </p>
        <Suspense fallback={null}>
          <GoodsGrid
            showSkeleton={isLoadingGoods || !collageGoods}
            showOwner={false}
            ref={ref}
            isLoadingNextPage={isFetchingNextPageGoods || hasNextPageGoods}
            openGoodModal={openGoodModal}
            goods={collageGoods}
          />
        </Suspense>
        <div ref={ref} /> {/* Scroll detection element */}
      </div>
    </BottomModal>
  );
}
