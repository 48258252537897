import { ReactComponent as CloseFilledIcon } from "assets/icons/close_filled.svg";
import ScrollToTopButton from "components/ScrollToTopButton";
import { memo, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";

const BottomModal = memo(function BottomModal({
  isOpen,
  onClose,
  children,
  className,
  zIndex,
  containerClassName = null,
  isNested = false,
  isFullHeight = false,
  isNotCloseable = false,
  isNotSwipable = false,
  isCloseIcon = true,
  scrollResetTrigger = false,
  bottomPadding = 40,
  noTopPadding = false,
}) {
  const [myIsOpen, setMyIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const startYRef = useRef(0);
  const modalRef = useRef(null);
  const modalContentRef = useRef(null);
  const backdropRef = useRef(null);
  const [modalRoot] = useState(document.createElement("div"));

  useEffect(() => {
    // Append the modal root to the body when the component mounts
    document.body.appendChild(modalRoot);

    // Clean up: remove the modal root from the body when the component unmounts
    return () => {
      document.body.removeChild(modalRoot);
    };
  }, [modalRoot]);

  useEffect(() => {
    if (isOpen !== myIsOpen) {
      if (!isOpen) {
        setIsClosing(true);
        if (modalRef.current) {
          modalRef.current.style.transform = "translateY(100%)"; // Reset modal position when closed
          backdropRef.current.style.opacity = 0;
        }
        setTimeout(() => {
          setMyIsOpen(false);
          setIsClosing(false);
        }, 300);
        if (!isNested) document.body.style.overflow = "auto";
      } else {
        setMyIsOpen(true);
        if (modalRef.current && backdropRef.current) {
          modalRef.current.style.willChange = "transform";
          backdropRef.current.style.willChange = "opacity";
          modalRef.current.style.transition =
            "transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)";
          backdropRef.current.style.transition =
            "opacity 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)";
          modalRef.current.style.transform = "translateY(0)";
          backdropRef.current.style.opacity = 1;
        }
        document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
      }
    }
    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling when modal is closed
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  }, [isOpen, scrollResetTrigger]);

  const handleTouchStart = (e, checkScroll) => {
    modalRef.current.style.transition = "none"; // Disable transition for dragging
    backdropRef.current.style.transition = "none"; // Disable transition for dragging
    if (!checkScroll || modalContentRef.current.scrollTop <= 0) {
      startYRef.current = e.touches[0].clientY;
    }
  };

  const handleTouchMove = (e, checkScroll) => {
    const touchY = e.touches[0].clientY;
    const moveY = touchY - startYRef.current;

    if (
      moveY > 0 &&
      (!checkScroll || modalContentRef.current.scrollTop <= 0) &&
      !isNotCloseable &&
      !isNotSwipable
    ) {
      // Prevent dragging up
      modalRef.current.style.transform = `translateY(${moveY}px)`;
      backdropRef.current.style.opacity = 1 - moveY / 600;
      setIsDragging(true);
    }
  };

  const handleTouchEnd = (e) => {
    const endY = e.changedTouches[0].clientY;
    const offsetY = endY - startYRef.current;
    modalRef.current.style.transition =
      "transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)";
    backdropRef.current.style.transition =
      "opacity 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)";
    if (modalRef.current && isDragging) {
      if (offsetY < 110) {
        // Snap back to original position
        modalRef.current.style.transform = "translateY(0)";
        backdropRef.current.style.opacity = 1;
        // setIsOpen(false);
      } else {
        // Close modal with animation
        onClose();
      }
    }
    setIsDragging(false);
  };

  const handleScroll = (e) => {
    if (isDragging) {
      e.preventDefault();
    }
  };

  return ReactDOM.createPortal(
    <>
      <div
        className={twMerge(
          `fixed inset-0 flex items-end justify-center ${
            !isClosing && !myIsOpen ? "invisible" : "visible"
          }`,
          isFullHeight ? "pt-0 md:pt-[80px]" : "pt-[80px]"
        )}
        style={{
          zIndex: zIndex,
        }}
      >
        <div
          className={`absolute inset-0 bg-black-opacity backdrop-blur opacity-0 cursor-pointer will-change-auto
          ${!isClosing && !myIsOpen ? "invisible" : "visible"}`}
          ref={backdropRef}
          onClick={() => (!isNotCloseable ? onClose() : null)}
        ></div>
        <div
          ref={modalRef}
          onTouchStart={(e) => {
            e.stopPropagation();
            handleTouchStart(e, true);
          }}
          onTouchMove={(e) => {
            e.stopPropagation();
            handleTouchMove(e, true);
          }}
          onTouchEnd={handleTouchEnd}
          className={twMerge(
            "rounded-t-3xl bg-white max-h-full w-full translate-y-full max-w-[550px] will-change-transform",

            isFullHeight ? "rounded-none h-full md:rounded-t-3xl" : "",
            containerClassName
          )}
        >
          <div
            className="absolute top-0 w-full h-6 flex justify-center items-center"
            onTouchStart={(e) => {
              e.stopPropagation();
              handleTouchStart(e, false);
            }}
            onTouchMove={(e) => {
              e.stopPropagation();
              handleTouchMove(e, false);
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              handleTouchEnd(e);
            }}
            style={{ zIndex: zIndex + 1 }}
          >
            <div
              className={`w-12 h-1.5 rounded-full bg-grey-3 ${
                isFullHeight ? "invisible md:visible" : ""
              }`}
            ></div>
          </div>
          <div
            className={twMerge(
              "overflow-auto h-full overscroll-y-none pt-4 max-h-[calc(var(--vh)*100)] rounded-t-3xl",
              className,
              noTopPadding && "pt-0"
            )}
            ref={modalContentRef}
            onScroll={handleScroll}
            style={{
              paddingTop:
                isFullHeight && !noTopPadding
                  ? "env(safe-area-inset-top)"
                  : undefined,
            }}
          >
            {children}
            {!isNotCloseable && isCloseIcon && (
              <CloseFilledIcon
                className={`absolute top-6 right-4 w-8 h-8 text-grey-3 cursor-pointer ${
                  isFullHeight ? "invisible" : "xl:visible"
                }`}
                onClick={onClose}
                onTouchStart={(e) => e.stopPropagation()}
                style={{ zIndex: zIndex + 1 }}
              />
            )}
            <ScrollToTopButton
              scrollRef={modalContentRef}
              baseBottom={bottomPadding}
            />
          </div>
        </div>
      </div>
    </>,
    modalRoot
  );
});

export default BottomModal;
