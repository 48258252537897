import { useMutation, useQueryClient } from "@tanstack/react-query";
import PrimaryButton from "components/PrimaryButton";
import { useForm } from "react-hook-form";
import { createCollage } from "services/api";
import { useToast } from "ToastContext";
import BottomModal from "../bottom_modal/BottomModal";

export const CollagePostModal = ({
  isOpen,
  onClose,
  collageJSON,
  collagePreview,
  bgColor,
}) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { addToast } = useToast();

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: createCollage,
    onSuccess: () => {
      addToast("Collage Created");
      onClose();
    },
    onSettled: () => {
      queryClient.invalidateQueries(["userCollections"]);
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("collage", JSON.stringify(collageJSON));
    formData.append("collage_preview", collagePreview);
    formData.append("bg_color", bgColor);
    mutate(formData);
  };

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      zIndex={21}
      containerClassName={"max-w-[550px] bg-white w-full"}
      noTopPadding={true}
      className={"max-h-[100vh]  h-full rounded-none md:rounded-t-3xl w-full"}
      isNested={true}
    >
      <div className="flex flex-col px-4 min-h-full pt-6">
        <h2 className="text-black text-xl font-bold mb-8">Post Collage</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-6">
            <input
              id="title"
              type="title"
              className="bg-grey-4 text-lg p-4 rounded-full w-full focus:outline focus:outline-1 outline-black"
              {...register("title", { required: true, minLength: 3 })}
              placeholder="Collage Name*"
            />
            {errors.title && (
              <p>Title is required and must be at least 3 characters long.</p>
            )}
            <PrimaryButton
              className="mt-20 w-full"
              type="submit"
              disabled={isPending}
            >
              {isPending ? "Creating..." : "Create Collage"}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </BottomModal>
  );
};
