import * as amplitude from "@amplitude/analytics-browser";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactComponent as OwnedIcon } from "assets/icons/bag.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as CollectionsIcon } from "assets/icons/collections.svg";
import { ReactComponent as GoodsIcon } from "assets/icons/goods.svg";
import { ReactComponent as HeartIcon } from "assets/icons/heart.svg";
import { RoundedPlus } from "assets/icons/RoundedPlus";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import { useGoodModal } from "GoodModalContext";
import { usePaywall } from "PaywallContext";
import { useUser } from "UserContext";
// import CollectionCreateModal from "components/modals/collection_modal/CollectionCreateModal";
import { useArchiveMutation } from "hooks/useArchiveMutation";
import { useCollectMutation } from "hooks/useCollectMutation";
import { useFetchGoods } from "hooks/useFetchGoods"; // Custom hook for fetching goods
import { useTelegramConnectMutation } from "hooks/useTelegramConnectMutation";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getHiddenGoods,
  removeFromExplore,
  unlockHiddenGoods,
} from "services/api";
import GoodsGrid from "./GoodsGrid";
import PrimaryButton from "./PrimaryButton";
// import { CollectionModal } from "./modals/collection_modal/CollectionModal";
import { CollageModal } from "./modals/collage_modal/CollageModal";
import AddGoodModal from "./modals/good_modal/AddGoodModal";

const CollectionCreateModal = lazy(() =>
  import("components/modals/collection_modal/CollectionCreateModal")
);
const CollectionModal = lazy(() =>
  import("components/modals/collection_modal/CollectionModal")
);

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const GoodsList = React.memo(function GoodsList({
  userId,
  selectedCategory,
  fetchGoods,
  pageType,
  queryName = null,
  userByLinkTag = null,
  removeMode = false,
  isVisible = true,
}) {
  const { openPaywall } = usePaywall();
  const { openGoodModal } = useGoodModal();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { user: currentUser } = useUser();
  const currentUserId = currentUser?.id;

  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
  const [isCollageModalOpen, setIsCollageModalOpen] = useState(false);
  const [selectedCollageId, setSelectedCollageId] = useState(null);
  const [collectionCreateOpen, setCollectionCreateOpen] = useState(false);
  const [AddGoodOpen, setAddGoodOpen] = useState(false);
  const [isPolling, setIsPolling] = useState(0);

  const query = useQueryParams();

  useEffect(() => {
    if (pageType === "owned") return;
    const goodId = query.get("good_id");
    const collectionId = query.get("collection_id");
    if (collectionId && userId) {
      openCollectionModal(collectionId);
    }
    if (goodId && userId) {
      openGoodModal(goodId, selectedCategory, userId);
    }
  }, [userId]);

  const {
    data: goods,
    isPending,
    isFetching,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch: refetchGoods,
  } = useFetchGoods(
    userId,
    selectedCategory,
    fetchGoods,
    queryName ? queryName : `${pageType}Goods`,
    isVisible && (pageType === "user" ? !!userId : true),
    removeMode
  );

  useEffect(() => {
    let interval;
    if (isPolling) {
      interval = setInterval(() => {
        refetchGoods();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isPolling, refetchGoods]);

  useEffect(() => {
    if (
      pageType !== "user" ||
      !goods?.pages?.length ||
      !goods.pages[0]?.goods?.length
    ) {
      return;
    }

    const fifteenMinutesAgoUTC = new Date(
      Date.now() - 15 * 60 * 1000
    ).toISOString();

    const hasUnparsedRecentGoods = goods.pages.some((page) =>
      page.goods?.some(
        (good) =>
          !good.is_parsed &&
          new Date(good.date_added).toISOString() >= fifteenMinutesAgoUTC
      )
    );

    if (hasUnparsedRecentGoods) {
      console.log("Polling for unparsed goods");
      setIsPolling(true);
    } else {
      console.log("No unparsed goods found, stopping polling");
      setIsPolling(false);
    }
  }, [goods, pageType]);

  const { data: hiddenGoods, isHiddenPending } = useQuery({
    queryKey: ["hiddenGoods", currentUserId],
    queryFn: () => getHiddenGoods(currentUserId),
    enabled:
      !!currentUserId && pageType === "user" && currentUser?.id === userId,
  });

  const { mutate: mutateUnlockHiddenGoods } = useMutation({
    mutationFn: () => unlockHiddenGoods(),
    onSuccess: () => {
      queryClient.invalidateQueries([`${pageType}Goods`, userId]);
      queryClient.invalidateQueries(["hiddenGoods", currentUserId]);
    },
  });

  const { mutate: removeFromExploreMutation } = useMutation({
    mutationFn: (goodId) => removeFromExplore(goodId),
    onSuccess: () => {
      queryClient.invalidateQueries([`${pageType}Goods`, userId]);
    },
  });

  const archiveMutation = useArchiveMutation(currentUserId, selectedCategory);

  const { mutate: collectMutation, isPending: isCollectPending } =
    useCollectMutation(currentUserId);
  const { mutate: handleConnectTelegram } = useTelegramConnectMutation();

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "0px 0px 500px 0px",
  });

  const openCollectionModal = useCallback((collectionId) => {
    setSelectedCollectionId(collectionId);
    setIsCollectionModalOpen(true);
  }, []);

  const openCollageModal = useCallback((collageId) => {
    setSelectedCollageId(collageId);
    setIsCollageModalOpen(true);
  }, []);

  const closeCollectionModal = useCallback(() => {
    setIsCollectionModalOpen(false);
  }, []);

  if (inView && hasNextPage && !isPending && !isFetching) {
    fetchNextPage();
  }
  // console.log("hiddenGoods", hiddenGoods);
  // console.log("goods", goods);
  // console.log("isLoading", isLoading);
  // console.log("isFetching", isFetching);
  // console.log("isPending", isPending);
  // console.log("isRefetching", isRefetching);
  return (
    <div className="mt-6 pb-[100px]">
      <GoodsGrid
        showSkeleton={isLoading || !goods}
        showOwner={pageType !== "user"}
        ref={ref}
        isLoadingNextPage={isFetchingNextPage || hasNextPage}
        openGoodModal={(goodId) => {
          if (removeMode) {
            removeFromExploreMutation(goodId);
          } else {
            openGoodModal(goodId, selectedCategory, userId, pageType);
          }
        }}
        openCollectionModal={(collectionId) => {
          openCollectionModal(collectionId, true);
          navigate(`?collection_id=${collectionId}`, {
            replace: true,
          });
        }}
        openCollageModal={(collageId) => {
          openCollageModal(collageId);
          navigate(`?collage_id=${collageId}`, {
            replace: true,
          });
        }}
        goods={goods}
        addons={(good) => {
          if (removeMode) {
            if (good.is_hidden_from_explore) {
              return (
                <div className="absolute w-full h-full z-10 flex items-center justify-center">
                  <CloseIcon className="w-40 h-40  text-red-500 " />
                </div>
              );
            } else {
              return (
                <div className="absolute flex top-4 left-4 z-10 text-white bg-primary rounded-full leading-none p-2">
                  <CloseIcon className="w-4 h-4 mr-1" /> Hide
                </div>
              );
            }
          }
        }}
        prepend={
          <>
            {(!hiddenGoods || hiddenGoods.count === 0) &&
              currentUser?.id === userId &&
              queryName === "userGoods" && (
                <>
                  <div
                    className="flex flex-col text-grey-2 text-center cursor-pointer text-sm justify-center items-center w-full h-full rounded-3xl bg-grey-4 aspect-[9_/_13] hover:filter hover:brightness-95"
                    onClick={() => {
                      amplitude.track("new_good_tapped");
                      setAddGoodOpen(true);
                      console.log("AddGoodOpen", AddGoodOpen);
                    }}
                  >
                    <RoundedPlus className="w-7 h-7 mb-1" /> New
                    <br />
                    Good
                  </div>
                  <AddGoodModal
                    isOpen={AddGoodOpen}
                    onClose={() => setAddGoodOpen(false)}
                  />
                  {goods?.pages[0].goods.length === 0 && (
                    <Link
                      className="flex flex-col text-grey-2 text-center cursor-pointer text-sm justify-center items-center w-full h-full rounded-3xl bg-grey-4 aspect-[9_/_13] hover:filter hover:brightness-95"
                      to="/explore"
                      onClick={() => {
                        amplitude.track("collect_tapped");
                      }}
                    >
                      <HeartIcon className="w-7 h-7 mb-1" /> Collect from
                      <br />
                      other people
                    </Link>
                  )}
                </>
              )}
            {(!hiddenGoods || hiddenGoods.count === 0) &&
              currentUser?.id === userId &&
              queryName === "userCollections" && (
                <>
                  <div
                    className="flex flex-col text-grey-2 text-center cursor-pointer text-sm justify-center items-center w-full h-full rounded-3xl bg-grey-4 aspect-[9_/_13] hover:filter hover:brightness-95"
                    onClick={() => {
                      setCollectionCreateOpen(true);
                    }}
                  >
                    <RoundedPlus className="w-7 h-7 mb-1" /> New
                    <br />
                    Collection
                  </div>
                  <Suspense fallback={null}>
                    <CollectionCreateModal
                      isOpen={collectionCreateOpen}
                      onClose={() => setCollectionCreateOpen(false)}
                    />
                  </Suspense>
                </>
              )}
            {hiddenGoods &&
              hiddenGoods.count > 0 &&
              queryName === "userGoods" &&
              currentUser?.id === userId && (
                <div
                  onClick={() =>
                    currentUser?.is_premium
                      ? mutateUnlockHiddenGoods()
                      : openPaywall("goods_limit", "goods_limit")
                  }
                  className='realtive flex flex-col relative rounded-3xl aspect-[9_/_13] object-contain cursor-pointer hover:bg-grey-3 hover:border-grey-3 hover:before:rotate-[0deg] hover:before:h-[100%] before:transition-transform before:content-[""] before:bg-grey-1 before:rounded-3xl before:h-[101%] before:w-[91%] before:absolute before:top-[-1px] before:right-[5px] before:z-[-1] before:rotate-[5deg] bg-white'
                >
                  <div className="flex flex-col items-center justify-center w-full h-full rounded-3xl overflow-hidden text-white text-lg">
                    <p className="absolute top-[50%] -translate-y-1/2 px-6 text-center z-[2]">
                      You've reached your limit with <br />
                      <strong>
                        {hiddenGoods.count} extra{" "}
                        {hiddenGoods.count > 1 ? "goods" : "good"}
                      </strong>
                    </p>
                    <button className="absolute top-[80%] -translate-y-1/2 left-[50%] -translate-x-1/2 text-sm text-nowrap p-2 px-3 bg-primary font-bold rounded-full z-[2]">
                      {currentUser?.is_premium
                        ? "Show hidden goods"
                        : "Remove Limit"}
                    </button>
                    <div className="absolute inset-0 z-[1] w-full h-full rounded-3xl bg-center bg-cover backdrop-blur-md "></div>
                    <div
                      className="absolute inset-0 z-0 w-full h-full rounded-3xl bg-center bg-cover"
                      style={{
                        backgroundImage: `url("https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/017b15b66ff2d38f14151d62b85d72f5")`,
                      }}
                    ></div>
                  </div>
                </div>
              )}
          </>
        }
      />
      <div ref={ref} /> {/* Scroll detection element */}
      {goods?.pages[0].goods.length === 0 && (
        <div className="flex flex-col items-center text-center text-lg text-black max-w-[340px] mx-auto mt-20">
          {pageType === "circle" ? (
            <>
              <UsersIcon className="w-6 h-6 text-grey-2 mb-4" />
              <p className="text-center text-lg text-black font-bold mb-2">
                No people in your Circle yet
              </p>
              <p className="text-center text-lg text-black">
                Start building your Circle by following friends and people whose
                style you like.
              </p>
            </>
          ) : userId && userId === currentUserId ? (
            queryName === "userOwned" && (
              <>
                <OwnedIcon className="w-8 h-8 text-grey-2 mb-3" />
                {!currentUser?.is_premium ? (
                  <>
                    <p className="text-center text-lg text-black">
                      Mark Owned Goods with <br /> Goodsend Premium
                    </p>
                    <PrimaryButton
                      onClick={() => openPaywall("owned", "owned")}
                      className="px-6 mt-6"
                    >
                      <StarIcon className="w-6 h-6 mr-2" />
                      Get Premium
                    </PrimaryButton>
                  </>
                ) : (
                  <p className="text-center text-lg text-black">
                    Keep track of your owned goods
                    <br /> without deleting them
                  </p>
                )}
              </>
            )
          ) : (
            <div className="flex flex-col items-center max-w-[340px] mx-auto mt-20">
              {queryName === "userGoods" ? (
                <>
                  <GoodsIcon className="w-6 h-6 text-grey-2 mb-4" />
                  <p className="text-center text-lg text-black">
                    {userByLinkTag?.name} has no
                    <br /> goods yet
                  </p>
                </>
              ) : queryName === "userCollections" ? (
                <>
                  <CollectionsIcon className="w-6 h-6 text-grey-2 mb-4" />
                  <p className="text-center text-lg text-black">
                    {userByLinkTag?.name} has no
                    <br /> collections yet
                  </p>
                </>
              ) : (
                <>
                  <OwnedIcon className="w-8 h-8 text-grey-2 mb-3" />
                  <p className="text-center text-lg text-black">
                    {userByLinkTag?.name} has no
                    <br /> owned goods yet
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      )}
      <Suspense fallback={null}>
        <CollectionModal
          isOpen={isCollectionModalOpen}
          onClose={closeCollectionModal}
          collectionId={selectedCollectionId}
          onArchiveClick={archiveMutation}
          onCollectClick={collectMutation}
        />
      </Suspense>
      <Suspense fallback={null}>
        <CollageModal
          isOpen={isCollageModalOpen}
          onClose={() => setIsCollageModalOpen(false)}
          collageId={selectedCollageId}
          openGoodModal={openGoodModal}
          // collectionId={selectedCollectionId}
          // onArchiveClick={archiveMutation}
          // onCollectClick={collectMutation}
        />
      </Suspense>
    </div>
  );
});

export default GoodsList;
