import { ReactComponent as CloseFilledIcon } from "assets/icons/close_filled.svg";
import { ReactComponent as ColorPickerIcon } from "assets/icons/color_picker.svg";
import { ReactComponent as EraserIcon } from "assets/icons/eraser.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";
import { RoundedPlus as RoundedPlusIcon } from "assets/icons/RoundedPlus";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as UndoIcon } from "assets/icons/undo.svg";
import { ReactComponent as UploadImageIcon } from "assets/icons/upload_image.svg";
import BottomModal from "components/modals/bottom_modal/BottomModal";
import { ColageGoodsModal } from "components/modals/collage_modal/CollageGoodsModal";
import * as fabric from "fabric";
import { useEffect, useRef, useState } from "react";
import { removeImageBackground } from "services/api";
import { CollagePostModal } from "./CollagePostModal";

export const CollageCreateModal = ({ isOpen, onClose }) => {
  const canvasRef = useRef(null); // Reference to the canvas DOM element
  const fabricCanvas = useRef(null); // Reference to the Fabric.js canvas instance
  const trashIconRef = useRef(null);
  const [selectedGoods, setSelectedGoods] = useState([]);
  const [isDragging, setIsDragging] = useState(false); // State to show/hide trash icon
  const [trashIntersected, setTrashIntersected] = useState(false); // State to show trash icon intersected
  const [isCollageGoodsOpen, setIsCollageGoodsOpen] = useState(false);
  const [selectedTextObject, setSelectedTextObject] = useState(null); // State for selected text object
  const [selectedImageObject, setSelectedImageObject] = useState(null); // State for selected image object
  const [textWeight, setTextWeight] = useState(400); // State for text weight
  const [textStyle, setTextStyle] = useState("normal"); // State for text style
  const [backgroundColor, setBackgroundColor] = useState("transparent"); // State for canvas background color
  const [isColorPickerMode, setIsColorPickerMode] = useState(false); // State for color picker mode
  const [lastUsedColors, setLastUsedColors] = useState([
    "#E5E5E5",
    "#868686",
    "#303030",
  ]); // State for last used colors
  const [lastUsedColor, setLastUsedColor] = useState(""); // State for last used color
  const [isClosePopupOpen, setIsClosePopupOpen] = useState(false); // State for close popup
  const [isPostCollageOpen, setIsPostCollageOpen] = useState(false); // State for post collage modal
  const [collagePreview, setCollagePreview] = useState(null); // State for collage preview
  const canvasStates = useRef([]); // Array to store canvas states for undo/redo
  let isRestoring = useRef(false); // Flag to prevent saving state when restoring

  const textWeights = [400, 500, 800, 900];

  const [textColor, setTextColor] = useState("#000000");

  useEffect(() => {
    const wrapper = document.getElementById("canvas-wrapper");
    // Initialize Fabric.js canvas

    fabricCanvas.current = new fabric.Canvas(canvasRef.current, {
      width: wrapper.clientHeight * (9 / 16),
      height: wrapper.clientHeight,
      backgroundColor: "transparent",
      renderOnAddRemove: true,
      enablePointerEvents: true,
    });

    const resizeCanvas = () => {
      let canvasWidth, canvasHeight;

      canvasWidth = wrapper.clientHeight * (9 / 16);
      canvasHeight = wrapper.clientHeight;

      if (canvasWidth > wrapper.clientWidth) {
        canvasWidth = wrapper.clientWidth;
        canvasHeight = wrapper.clientWidth * (16 / 9);
      }
      fabricCanvas.current.setDimensions({
        width: canvasWidth,
        height: canvasHeight,
      });
    };
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    fabricCanvas.current.preserveObjectStacking = true;

    saveCanvasState("initialization");
    // Event listeners for object selection and dragging
    fabricCanvas.current.on("selection:updated", onObjectSelected);
    fabricCanvas.current.on("selection:created", onObjectSelected);
    fabricCanvas.current.on("selection:cleared", () => {
      setSelectedTextObject(null); // Reset state if another object is selected
      setSelectedImageObject(null);
      setIsColorPickerMode(false);
    });
    fabricCanvas.current.on("object:moving", onObjectMoving);
    fabricCanvas.current.on("object:modified", onObjectModified);
    fabricCanvas.current.on("mouse:down", onMouseDown);

    // Event listeners for undo functionality
    fabricCanvas.current.on("object:added", onObjectAdded);
    fabricCanvas.current.on("object:removed", onObjectRemoved);

    // Clean up on unmount
    return () => {
      window.removeEventListener("resize", resizeCanvas);
      fabricCanvas.current.off("selection:cleared", () => {
        setSelectedTextObject(null); // Reset state if another object is selected
        setSelectedImageObject(null);
        setIsColorPickerMode(false);
      });
      fabricCanvas.current.off("mouse:down", onMouseDown);
      fabricCanvas.current.off("selection:updated", onObjectSelected);
      fabricCanvas.current.off("selection:created", onObjectSelected);
      fabricCanvas.current.off("object:moving", onObjectMoving);
      fabricCanvas.current.off("object:modified", onObjectModified);
      fabricCanvas.current.off("object:added", onObjectAdded);
      fabricCanvas.current.off("object:removed", onObjectRemoved);
      fabricCanvas.current.dispose();
      setSelectedTextObject(null);
      setSelectedImageObject(null);
      setSelectedGoods([]);
      setBackgroundColor("#ffffff");
    };
  }, []);

  // Function to save the current state of the canvas
  const saveCanvasState = (triggredBy = null) => {
    if (!isRestoring.current) {
      const canvasJson = fabricCanvas.current.toDatalessJSON([
        "goodId",
        "backgroundRemoved",
      ]);
      console.log("canvasJson");
      console.log(canvasJson);
      canvasStates.current.push(canvasJson);
      console.log("saved state, triggered by ", triggredBy);
      console.log(canvasStates);
    }
  };
  // Event handler for object added
  const onObjectAdded = (e) => {
    console.log(`object added`);
    console.log(e);
    console.log(e.target.goodId);
    if (e.target.goodId) {
      setSelectedGoods((prev) => [...prev, e.target.goodId]);
    }
    if (e.target.isShimmer) return;
    saveCanvasState("object added");
  };

  // Event handler for object removed
  const onObjectRemoved = (e) => {
    console.log(`object removed`);
    console.log(e);
    const goodId = e.target.goodId;
    if (goodId) {
      setSelectedGoods((prev) => prev.filter((id) => id !== goodId));
    }

    saveCanvasState("object removed");
  };

  // Function to undo the last action
  const undo = () => {
    if (canvasStates.current.length > 1) {
      isRestoring.current = true;
      // Remove the current state
      console.log("undo");
      console.log(canvasStates);
      canvasStates.current.pop();
      console.log(canvasStates);
      // Get the previous state
      const previousState =
        canvasStates.current[canvasStates.current.length - 1];

      fabricCanvas.current.loadFromJSON(previousState).then((e) => {
        console.log("finished restoring");
        isRestoring.current = false;
        console.log(e);
        fabricCanvas.current.renderAll();
      });
    }
  };

  const onMouseDown = (e) => {
    setIsColorPickerMode(false);
  };

  // Function to add an image to the canvas
  const addImageToCanvas = (url, goodId, backgroundRemoved) => {
    if (!fabricCanvas.current) {
      console.error("Canvas is not initialized yet");
      return;
    }
    return new Promise((resolve, reject) => {
      fabric.FabricImage.fromURL(
        url,
        {
          crossOrigin: "anonymous",
        },
        { goodId: goodId, backgroundRemoved: backgroundRemoved }
      )
        .then((img) => {
          img.scaleToWidth(fabricCanvas.current.width / 2);
          img.set({
            left:
              fabricCanvas.current.width / 2 - fabricCanvas.current.width / 4,
            top:
              fabricCanvas.current.height / 2 - fabricCanvas.current.height / 4,
          });

          fabricCanvas.current.add(img);
          fabricCanvas.current.setActiveObject(img);

          resolve(img);
        })
        .catch((err) => {
          console.error("Error adding image to canvas:", err);
          reject(err);
        });
    });
  };

  // Function to add text to the canvas
  const addTextToCanvas = () => {
    if (!fabricCanvas.current) {
      console.error("Canvas is not initialized yet");
      return;
    }
    const text = new fabric.Textbox("Text", {
      left: fabricCanvas.current.width / 2 - 48,
      top: fabricCanvas.current.height / 2 - 48,
      fill: textColor,
      fontSize: 48,
      selectable: true,
      editable: true,
      fontFamily: "GT Flexa, system-ui, sans-serif",
      fontWeight: textWeight,
      //   fontStyle: "italic",
    });
    fabricCanvas.current.add(text);
    fabricCanvas.current.setActiveObject(text);
    // setTextInput(""); // Clear text input after adding
  };
  useEffect(() => {
    if (!isColorPickerMode) {
      if (
        !lastUsedColors.includes(lastUsedColor) &&
        lastUsedColor !== "transparent" &&
        lastUsedColor !== ""
      ) {
        setLastUsedColors((prev) => {
          return [...prev, lastUsedColor].slice(-3);
        });
      }
    }
  }, [isColorPickerMode]);

  // Function to update the color of the selected text object
  const updateTextColor = (color) => {
    setTextColor(color);
    if (!fabricCanvas.current) {
      console.error("Canvas is not initialized yet");
      return;
    }
    selectedTextObject.set("fill", color);
    fabricCanvas.current.renderAll();
    setLastUsedColor(color);
  };
  const updateTextWeight = (weight) => {
    const index = textWeights.indexOf(weight);
    setTextWeight(textWeights[(index + 1) % textWeights.length]);

    selectedTextObject.set(
      "fontWeight",
      textWeights[(index + 1) % textWeights.length]
    );
    fabricCanvas.current.renderAll();
  };

  // Function to save the canvas as an image
  const handleSaveCanvas = () => {
    if (!fabricCanvas.current) {
      console.error("Canvas is not initialized yet");
      return;
    }

    canvasStates.current[canvasStates.current.length - 1].width =
      fabricCanvas.current.width;
    canvasStates.current[canvasStates.current.length - 1].height =
      fabricCanvas.current.height;

    const scaleFactor = 3;

    // Convert the canvas to a data URL (high-resolution image)
    const dataURL = fabricCanvas.current.toDataURL({
      format: "webp",
      quality: 1,
      multiplier: scaleFactor, // Use multiplier for higher resolution
    });

    // Convert data URL to a Blob
    const dataURLToBlob = (dataURL) => {
      const arr = dataURL.split(",");
      const mimeMatch = arr[0].match(/:(.*?);/);
      const mime = mimeMatch ? mimeMatch[1] : "image/png";
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], { type: mime });
    };

    const blob = dataURLToBlob(dataURL);
    const file = new File([blob], "high-quality-collage.webp", {
      type: "image/webp",
    });

    setCollagePreview(file);
    setIsPostCollageOpen(true);
    // // Share or save the file
    // if (navigator.canShare && navigator.canShare({ files: [file] })) {
    //   navigator
    //     .share({
    //       files: [file],
    //       title: "High-Quality Collage",
    //       text: "Check out this high-quality collage!",
    //     })
    //     .then(() => console.log("Canvas shared successfully!"))
    //     .catch((err) => console.error("Error sharing canvas:", err));
    // } else {
    //   console.error("Web Share API not supported or unable to share the file.");
    // }
  };

  // Function to show the trash icon when an object is moving
  const onObjectMoving = (e) => {
    // setIsDragging(true); // Show the trash icon
    // const object = e.target;
    // // Get canvas and object bounding boxes
    // const canvasElement = canvasRef.current;
    // const canvasRect = canvasElement.getBoundingClientRect();
    // const objectBoundingBox = object.getBoundingRect();
    // const objectViewportBoundingBox = {
    //   left: objectBoundingBox.left + canvasRect.left,
    //   top: objectBoundingBox.top + canvasRect.top,
    //   width: objectBoundingBox.width,
    //   height: objectBoundingBox.height,
    // };
    // // Get trash icon bounding box
    // const trashIconBoundingBox = trashIconRef.current.getBoundingClientRect();
    // // Check if object overlaps trash icon
    // if (intersectRect(objectViewportBoundingBox, trashIconBoundingBox)) {
    //   setTrashIntersected(true);
    // } else {
    //   setTrashIntersected(false);
    // }
  };

  // Function to hide the trash icon and remove the object if over the trash icon
  const onObjectModified = (e) => {
    if (!e.e) return;

    // const object = e.target;
    // const canvasElement = canvasRef.current;
    // const canvasRect = canvasElement.getBoundingClientRect();

    // const objectBoundingBox = object.getBoundingRect();
    // const objectViewportBoundingBox = {
    //   left: objectBoundingBox.left + canvasRect.left,
    //   top: objectBoundingBox.top + canvasRect.top,
    //   width: objectBoundingBox.width,
    //   height: objectBoundingBox.height,
    // };

    // const trashIconBoundingBox = trashIconRef.current.getBoundingClientRect();

    // if (intersectRect(objectViewportBoundingBox, trashIconBoundingBox)) {
    //   fabricCanvas.current.remove(object);
    // } else {
    saveCanvasState(`object modified by ${e.e}`);
    // }
    // setIsDragging(false); // Hide the trash icon after object is moved
  };

  // Helper function to check if two rectangles intersect
  const intersectRect = (r1, r2) => {
    return !(
      r2.left > r1.left + r1.width ||
      r2.left + r2.width < r1.left ||
      r2.top > r1.top + r1.height ||
      r2.top + r2.height < r1.top
    );
  };
  const removeGoodFromCanvas = (good) => {
    setSelectedGoods((prev) => prev.filter((id) => id !== good.id));
    fabricCanvas.current.getObjects().forEach((obj) => {
      if (obj.goodId === good.id) {
        fabricCanvas.current.remove(obj);
      }
    });
  };

  const addOrRemoveGoodToCanvas = (good) => {
    if (selectedGoods.includes(good.id)) {
      removeGoodFromCanvas(good);
    } else {
      setSelectedGoods((prev) => [...prev, good.id]);
      console.log(good.image_type);
      addImageToCanvas(
        `https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${good.image_url}`,
        good.id,
        good.image_type === "1"
      );
    }
  };

  const onObjectSelected = (e) => {
    const object = e.selected[0];
    if (object) {
      fabricCanvas.current.bringObjectToFront(object);
      saveCanvasState("object selected");
      object.set({
        borderColor: "#4a5b39", // Change the border color of the selection
        cornerColor: "#4a5b39", // Change the color of the corner resize controls
        cornerStrokeColor: "#4a5b39", // Change the border color of the corner controls
        cornerSize: 10, // Change the size of the corner squares (optional)
        cornerStyle: "none", // Make the corners appear as circles (optional)
      });
    }
    // console.log(e);
    if (object) {
      if (object.type === "textbox")
        setSelectedTextObject(object); // Update state with selected text
      else if (object.type === "image") setSelectedImageObject(object);
    } else {
      setSelectedTextObject(null); // Reset state if another object is selected
      setSelectedImageObject(null);
      setIsColorPickerMode(false);
    }
  };

  const extractImageFileFromFabricImage = (fabricImage) => {
    return new Promise((resolve, reject) => {
      fetch(fabricImage.getSrc())
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "image.png", {
            type: "image/png",
          });
          resolve(file);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateFabricImageSrc = (fabricImage, newUrl) => {
    // Get the underlying image element
    const imgElement = fabricImage.getElement();

    // Set the new image source
    imgElement.src = newUrl;

    // Listen for the image load event to ensure the image is fully loaded before updating the canvas
    imgElement.onload = () => {
      // After the image is loaded, you might want to reset the size or scale
      fabricImage.set({
        width: imgElement.width,
        height: imgElement.height,
        scaleX: fabricImage.scaleX || 1,
        scaleY: fabricImage.scaleY || 1,
      });

      // Update the image's coordinates and re-render the canvas
      fabricImage.setCoords();
      fabricImage.canvas.renderAll();
    };

    // Handle errors, in case the image fails to load
    imgElement.onerror = () => {
      console.error("Failed to load new image source.");
    };
  };

  const addShimmerEffect = (fabricCanvas, fabricImage) => {
    // Create a shimmer overlay rectangle

    const shimmer = new fabric.Rect({
      left: fabricImage.left,
      top: fabricImage.top,
      width: fabricImage.width * fabricImage.scaleX * 0.25,
      height: fabricImage.height * fabricImage.scaleY,
      selectable: false,
      evented: false,
    });

    // Apply a linear gradient for the shimmer effect
    const gradient = new fabric.Gradient({
      type: "linear",
      gradientUnits: "percentage",
      coords: { x1: 0, y1: 0, x2: 1, y2: 0 },
      colorStops: [
        { offset: 0, color: "rgba(255,255,255,0)", opacity: 0 },
        { offset: 0.5, color: "rgba(255,255,255,0.05)", opacity: 0.1 },
        { offset: 1, color: "rgba(255,255,255,0)", opacity: 0 },
      ],
    });

    // Set the gradient as the fill of the shimmer
    shimmer.set("fill", gradient);
    shimmer.isShimmer = true;
    fabricImage.set("lockMovementX", true);
    fabricImage.set("lockMovementY", true);

    // // Add the shimmer to the canvas
    fabricCanvas.add(shimmer);
    fabricCanvas.bringObjectToFront(shimmer);
    fabricCanvas.renderAll();
    shimmer.set({
      left: fabricImage.left,
    });

    const animateShimmerFront = () => {
      shimmer.animate(
        {
          left:
            fabricImage.left +
            fabricImage.width * fabricImage.scaleX -
            fabricImage.width * fabricImage.scaleX * 0.25,
        },
        {
          duration: 1000, // 1 second shimmer effect
          onChange: () => fabricCanvas.renderAll(),
          easing: fabric.util.ease.easeInOutQuad,
          onComplete: AnimateShimmerBack, // Loop the shimmer animation
        }
      );
    };
    const AnimateShimmerBack = () => {
      shimmer.animate(
        {
          left: fabricImage.left,
        },
        {
          duration: 1000, // 1 second shimmer effect
          onChange: () => fabricCanvas.renderAll(),
          easing: fabric.util.ease.easeInOutQuad,
          onComplete: animateShimmerFront, // Loop the shimmer animation
        }
      );
    };

    // // Start the shimmer animation
    animateShimmerFront();
    // console.log("shimmer");

    return shimmer; // Return shimmer object to remove later
  };

  const removeSelectedImageBackground = () => {
    if (!selectedImageObject) return;
    const image = selectedImageObject;
    const canvas = fabricCanvas.current;

    const fabricImage = image;
    const file = extractImageFileFromFabricImage(fabricImage);
    let shimmer = addShimmerEffect(canvas, fabricImage);
    file.then((imageFile) => {
      removeImageBackground(imageFile)
        .then((response) => {
          fabricCanvas.current.remove(shimmer);
          fabricImage.set("lockMovementX", false);
          fabricImage.set("lockMovementY", false);
          updateFabricImageSrc(
            fabricImage,
            `https://goodsend.fra1.cdn.digitaloceanspaces.com/main-space/images/${response.filename}`
          );
          fabricImage.set("backgroundRemoved", true);
        })
        .catch((error) => {
          console.error("Error removing background:", error);
        });
    });
  };
  const deleteSelectedObject = () => {
    fabricCanvas.current.remove(fabricCanvas.current.getActiveObject());
  };

  const updateBackgroundColor = (color) => {
    setBackgroundColor(color);
    fabricCanvas.current.backgroundColor = color;
    fabricCanvas.current.renderAll();
    saveCanvasState("background color changed");
    setLastUsedColor(color);
  };

  const onPopupClose = () => {
    if (canvasStates.current.length > 1) {
      setIsClosePopupOpen(true);
    } else {
      onClose();
    }
  };

  const cleanCanvas = () => {
    fabricCanvas.current.clear();
    canvasStates.current = canvasStates.current.slice(0, 1);
    setBackgroundColor("transparent");
    setSelectedTextObject(null);
    setSelectedImageObject(null);
    setSelectedGoods([]);
  };

  return (
    <BottomModal
      onClose={onPopupClose}
      zIndex={11}
      containerClassName={
        "max-w-[550px] rounded-none sm:w-fit sm:rounded-t-[28px] md:rounded-t-[28px]"
      }
      isFullHeight={true}
      //   noTopPadding={true}
      className={
        "max-h-[100vh] h-full rounded-none overflow-hidden bg-black sm:rounded-t-[28px]"
      }
      isNested={true}
      isOpen={isOpen}
      isNotSwipable={true}
      isCloseIcon={true}
    >
      <div className="flex flex-col h-full bg-black box-border">
        <div
          className=" flex justify-center w-full h-full flex-grow overflow-hidden select-none"
          id="canvas-wrapper"
        >
          <div
            className=" relative rounded-[28px] max-w-fit h-fit"
            style={{
              backgroundColor: "white",
              backgroundImage: "radial-gradient(gray 5%, transparent 8%)",
              backgroundSize: "20px 20px",
            }}
          >
            <div className="flex justify-between items-center absolute top-4 w-full z-[21]">
              <div className="flex items-center">
                <CloseFilledIcon
                  className={`drop-shadow block ml-4 mr-2 w-10 h-10 text-grey-2 cursor-pointer hover:filter hover:brightness-95 active:scale-[0.985]`}
                  onClick={onPopupClose}
                  onTouchStart={(e) => e.stopPropagation()}
                />

                <UndoIcon
                  className="drop-shadow w-[33px] h-[33px] text-white hover:filter hover:brightness-95 active:scale-[0.985]"
                  onClick={undo}
                />
              </div>
              <button
                className="block mr-4 p-2 px-6 text-white cursor-pointer bg-primary rounded-full text-sm/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={handleSaveCanvas}
              >
                Save
              </button>
            </div>
            <canvas
              id="collage-canvas"
              ref={canvasRef}
              className=" rounded-3xl"
            />
          </div>
        </div>

        <div
          className={`relative flex justify-center items-center w-full pt-4 flex-shrink-0 box-border ${
            isColorPickerMode ? "space-x-4" : "space-x-8"
          }`}
          style={{ paddingBottom: "max(env(safe-area-inset-bottom), 12px)" }}
        >
          {isColorPickerMode ? (
            <div className="relative flex justify-center items-center w-full pb-4 space-x-4">
              <CloseFilledIcon
                className={`block w-10 h-10 text-grey-2 cursor-pointer hover:filter hover:brightness-95 active:scale-[0.985]`}
                onClick={() => setIsColorPickerMode(false)}
                onTouchStart={(e) => e.stopPropagation()}
              />
              {!selectedTextObject && (
                <button
                  className={`w-12 h-12 rounded-xl bg-black
                    ${
                      backgroundColor === "transparent" &&
                      "border-[3px] border-white"
                    }`}
                  style={{
                    backgroundColor: "white",
                    backgroundImage: `
                      linear-gradient(45deg, #eee 25%, transparent 25%), 
                      linear-gradient(135deg, #eee 25%, transparent 25%), 
                      linear-gradient(45deg, transparent 75%, #eee 75%), 
                      linear-gradient(135deg, transparent 75%, #eee 75%)
                    `,
                    backgroundSize: "12px 12px",
                    backgroundPosition: "0 0, 6px 0, 6px -6px, 0px 6px",
                  }}
                  onClick={() => updateBackgroundColor("transparent")}
                ></button>
              )}
              {lastUsedColors.map((color, index) => (
                <button
                  key={index}
                  className={`w-12 h-12 rounded-xl bg-black
                    ${
                      ((selectedTextObject &&
                        selectedTextObject.fill === color) ||
                        (!selectedTextObject && backgroundColor === color)) &&
                      "border-[3px] border-white"
                    }`}
                  style={{ backgroundColor: color }}
                  onClick={() =>
                    selectedTextObject
                      ? updateTextColor(color)
                      : updateBackgroundColor(color)
                  }
                ></button>
              ))}

              <div className="relative hover:filter hover:brightness-95 active:scale-[0.985] cursor-pointer">
                <button className="flex flex-col items-center text-xs/tight text-white ">
                  <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-grey-1">
                    <ColorPickerIcon className="w-6 h-6 text-black" />
                  </div>
                  {/* Change <br /> Color */}
                </button>
                <input
                  id="text-color-input"
                  className="absolute w-full h-full inset-0 opacity-0 cursor-pointer"
                  type="color"
                  value={textColor}
                  onChange={(e) =>
                    selectedTextObject
                      ? updateTextColor(e.target.value)
                      : updateBackgroundColor(e.target.value)
                  }
                />
              </div>
            </div>
          ) : selectedTextObject ? (
            <>
              <button
                className="text-white text-xs/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={() => {
                  selectedTextObject.enterEditing();
                  selectedTextObject.hiddenTextarea.focus();
                }}
              >
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <PencilIcon className="h-6" />
                </div>
                {/* Edit <br /> Text */}
              </button>
              <button
                className="text-xs/tight text-white shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={(e) => updateTextWeight(selectedTextObject.fontWeight)}
              >
                <div
                  className="text-xl text-black flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2"
                  style={{ fontWeight: selectedTextObject.fontWeight }}
                >
                  A
                </div>
                {/* Change <br /> Weight */}
              </button>

              <button
                className="flex flex-col items-center text-xs/tight text-white hover:filter hover:brightness-95 active:scale-[0.985] cursor-pointer"
                onClick={() => setIsColorPickerMode(true)}
              >
                <div className="flex items-center justify-center w-14 h-14 rounded-full bg-white mb-2">
                  <div
                    className="w-7 h-7 rounded-full border-2 border-black"
                    style={{ backgroundColor: selectedTextObject.fill }}
                  ></div>
                </div>
                {/* Change <br /> Color */}
              </button>

              <button
                className="text-white text-xs/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={deleteSelectedObject}
              >
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <TrashIcon className="w-8 h-8 text-black" />
                </div>
                {/* Delete <br /> Text */}
              </button>
            </>
          ) : selectedImageObject ? (
            <>
              {!selectedImageObject.backgroundRemoved && (
                <button
                  className="flex flex-col items-center justify-center text-white text-xs/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                  onClick={removeSelectedImageBackground}
                >
                  <div
                    className="relative text-black flex items-center justify-center w-14 h-14 rounded-full mb-2"
                    style={{
                      backgroundColor: "white",
                      backgroundImage: `
                      linear-gradient(45deg, #eee 25%, transparent 25%), 
                      linear-gradient(135deg, #eee 25%, transparent 25%), 
                      linear-gradient(45deg, transparent 75%, #eee 75%), 
                      linear-gradient(135deg, transparent 75%, #eee 75%)
                    `,
                      backgroundSize: "12px 12px",
                      backgroundPosition: "0 0, 6px 0, 6px -6px, 0px 6px",
                    }}
                  >
                    <span className="absolute top-[-8px] left-[16px] text-white bg-primary py-0.5 px-1.5 rounded-full">
                      AI
                    </span>
                    <EraserIcon className="w-6 mb-1" />
                  </div>
                  {/* Remove <br /> Background */}
                </button>
              )}
              <button
                className="text-white text-xs/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={deleteSelectedObject}
              >
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <TrashIcon className="w-8 h-8 text-black" />
                </div>
                {/* Delete <br /> Good */}
              </button>
            </>
          ) : (
            <>
              <button
                className=" text-white text-xs/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={() => setIsCollageGoodsOpen(true)}
              >
                <div className="flex justify-center items-center bg-primary w-14 h-14 rounded-full mb-2">
                  <RoundedPlusIcon className="w-5 h-5 text-white" />
                </div>
                {/* Add <br /> Goods */}
              </button>
              <button
                className="text-white text-xs/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                onClick={addTextToCanvas}
              >
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <TextIcon className="h-4" />
                </div>
                {/* Add <br /> Text */}
              </button>
              <button
                className="flex flex-col items-center text-xs/tight text-white hover:filter hover:brightness-95 active:scale-[0.985] cursor-pointer"
                onClick={() => setIsColorPickerMode(true)}
              >
                <div className="flex items-center justify-center w-14 h-14 rounded-full bg-white mb-2">
                  <div
                    className="w-7 h-7 rounded-full border-2 border-black"
                    style={{ backgroundColor: backgroundColor }}
                  ></div>
                </div>
                {/* Change <br /> Color */}
              </button>

              <button
                className="relative text-white text-xs/tight shadow hover:filter hover:brightness-95 active:scale-[0.985]"
                // onClick={addTextToCanvas}
              >
                <span className="absolute top-[-6px] left-[5px]  bg-primary py-0.5 px-1.5 rounded-full">
                  SOON
                </span>
                <div className="flex items-center justify-center bg-white w-14 h-14 rounded-full mb-2">
                  <UploadImageIcon className="w-5" />
                </div>
                {/* Upload <br /> Image */}
              </button>
            </>
          )}
        </div>
      </div>

      <ColageGoodsModal
        isOpen={isCollageGoodsOpen}
        onClose={() => setIsCollageGoodsOpen(false)}
        addGood={addOrRemoveGoodToCanvas}
        selectedGoods={selectedGoods}
      />
      <CollagePostModal
        isOpen={isPostCollageOpen}
        onClose={() => setIsPostCollageOpen(false)}
        collageJSON={canvasStates.current[canvasStates.current.length - 1]}
        bgColor={backgroundColor}
        collagePreview={collagePreview}
      />
      <BottomModal
        isOpen={isClosePopupOpen}
        onClose={() => setIsClosePopupOpen(false)}
        zIndex={21}
        isNested={true}
        // containerClassName="rounded-t-[28px] sm:rounded-t-[28px] md:rounded-t-[28px]"
        // isFullHeight={false}
        // className="rounded-t-[28px] bg-black"
      >
        <div className="flex flex-col justify-center items-center w-full h-full">
          <div className="text-center text-xl font-bold mt-4 mb-12 px-12">
            You're about to loose your changes
            <br />
            <br /> Are you sure?
          </div>
          <div className="flex mb-6 pb-[40px]">
            <button
              className="flex justify-center items-center w-full h-12 mr-6 rounded-3xl border border-primary p-4 text-primary text-sm"
              onClick={() => setIsClosePopupOpen(false)}
            >
              Cancel
            </button>
            <button
              className="flex justify-center items-center w-full h-12 rounded-3xl bg-primary p-4 text-white text-sm"
              onClick={() => {
                onClose();
                cleanCanvas();
                setIsClosePopupOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </BottomModal>
    </BottomModal>
  );
};
